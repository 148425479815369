<template>
	<section
		class="content-grid"
		:class="{ 'load-more': content.loadMore, dark }"
	>
		<div class="grid-columns-2 margin-bottom-xl">
			<TnHeading
				v-if="content.heading"
				type="h2"
				responsive
				v-editable-string
				>{{ content.heading }}
			</TnHeading>
			<TnLink
				class="read-more"
				v-if="moreArticlesLink"
				v-bind="moreArticlesLink"
				arrowRight
				size="l"
			/>
		</div>
		<TransitionGroup
			tag="div"
			name="slide"
			class="cards-wrapper"
		>
			<div
				v-for="row in numVisibleRows"
				:key="'row-' + row"
				:class="[`cards grid-columns-${numCols}`, { scroll }]"
			>
				<ContentGridCard
					v-for="(article, index) in articles?.slice((row - 1) * numCols, row * numCols)"
					:key="`article-card-${index}`"
					:class="[`card`, { 'text-only': textOnly }]"
					:largeTitles="largeTitles"
					:textOnly="textOnly"
					:aspect-ratio-mobile="/*scroll ? `386:232` :*/ `1:1`"
					:imageZoom="content.zoomImages || false"
					:article="article"
					:dark="dark"
					tabindex="0"
				/>
			</div>
		</TransitionGroup>

		<div
			v-if="moreToLoad && content.loadMoreText && !scroll && !textOnly"
			class="load-more-link-wrap margin-top-2xl"
		>
			<LinkInline @click="loadNextRow">{{ content.loadMoreText }}</LinkInline>
		</div>
	</section>
</template>

<script>
import ResponsiveImageViewModel from "~/helpers/ViewModels/ResponsiveImageViewModel.js";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";
import SupportsDarkMode from "../../../../mixins/platform/SupportsDarkMode.js";
import GizmoComponentMixin from "../../../../mixins/platform/GizmoComponentMixin.js";

export default defineNuxtComponent({
	name: "ContentGrid",
	props: ["rows"],

	mixins: [SupportsDarkMode, GizmoComponentMixin],

	data() {
		return {
			numVisibleRows: 1,
		};
	},

	computed: {
		articles() {
			return (
				this.component?.content?.articles?.map((article) => {
					return {
						...article,
						image: ResponsiveImageViewModel(article.image),
						articleLink: LinkViewModel({
							externalLink: article.articleLink,
							internalLink: article.internalLink,
						}),
					};
				}) || []
			);
		},
		scroll() {
			return !!this.content.scroll;
		},
		gridType() {
			return this.content.gridType;
		},
		numCols() {
			if (this.content.scroll) return this.articles?.length;
			else return this.gridType ? this.gridType[0] : 3;
		},
		numRows() {
			return (this.articles && Math.ceil(this.articles.length / this.numCols)) || 0;
		},
		largeTitles() {
			return this.gridType?.indexOf("large text") > -1;
		},
		textOnly() {
			return this.gridType?.indexOf("only text") > -1;
		},
		moreToLoad() {
			return this.numVisibleRows < this.numRows;
		},
		moreArticlesLink() {
			if (this.content.moreArticlesUrl) {
				return LinkViewModel({ to: this.content.moreArticlesUrl, text: "Se flere artikler" });
			} else {
				return null;
			}
		},
	},

	methods: {
		loadNextRow() {
			this.numVisibleRows++;
		},
	},

	mounted() {
		if (!this.content.loadMore) this.numVisibleRows = this.numRows;
	},
});
</script>

<style lang="scss" scoped>
.load-more-link-wrap {
	text-align: center;

	@include font-text-l;
}

.dark {
	color: #fff;

	:deep(h2) {
		color: inherit;
	}

	:deep(.cards .card .card-container .text) {
		color: inherit;

		.card-heading {
			color: inherit;
		}
	}
}

@include breakpoint(mobile) {
	.grid-columns-2 {
		grid-template-columns: 1fr;
	}
}

.read-more {
	grid-column: 2 / 3;
	display: flex;
	justify-content: flex-end;

	@include breakpoint(mobile) {
		grid-row: 2 / 3;
		grid-column: 1 / 2;
		justify-content: flex-start;
	}
}

@include breakpoint(mobile) {
	.cards-wrapper {
		display: grid;
		gap: $spacing-xl;
	}
}

.cards {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 1fr;
	grid-template-columns: repeat(2, 1fr);
	flex-wrap: nowrap;

	:focus-visible {
		outline-offset: 2px;
	}

	+ .cards {
		margin-top: $spacing-xl;

		@include breakpoint(mobile) {
			margin-top: 0;
		}
	}

	a {
		text-decoration: none;
	}

	&.scroll {
		@include breakpoint(mobile) {
			overflow-x: auto;
			flex-wrap: nowrap;
		}
	}

	.card {
		display: block;
		min-width: 170px;
		transition: all 0.2s ease;

		&:hover {
			a {
				img {
					@media screen and (min-width: $size-screen-phone) {
						opacity: 0.6;
						transition: opacity 0.5s ease-out;
					}
				}
			}

			:deep(.card-link .card-heading) {
				text-decoration: underline;

				@include breakpoint(mobile) {
					text-decoration: none;
				}
			}
		}

		&.text-only ~ .text-only {
			border-left: thin solid $color-neutrals-200-tint;
			padding: 0 $spacing-m 0 $spacing-l;

			@media screen and (max-width: $size-screen-phone - 1) {
				padding: 0 $spacing-s 0 $spacing-m;
			}
		}

		&.large-text {
			@include breakpoint(tablet) {
				:deep(.card-link) {
					display: flex;
					flex-direction: column;

					.image-wrap {
						width: 100%;
					}

					.text {
						width: 100% !important;
						padding: 0 !important;
					}
				}
			}
		}

		:deep(.card-container) {
			width: 100%;

			img {
				width: 100%;

				@include breakpoint(mobile) {
					object-fit: cover;
					aspect-ratio: 1;
				}
			}

			.text {
				font-family: $font-family-telenor-ui;
				text-align: left;
				color: $color-text;

				&.center {
					text-align: center;
				}

				.categories {
					overflow: hidden;
					text-overflow: clip;
					max-height: 24px;
					margin-bottom: 0;
				}

				.card-heading {
					color: $color-text;
				}
			}
		}
	}

	@include breakpoint(mobile) {
		grid-auto-flow: row;
		grid-template-columns: none;
		row-gap: $spacing-xl;

		.text-only {
			.text {
				padding: $spacing-m 0;
			}

			& ~ .text-only {
				border-left: none;
				border-top: thin solid $color-neutrals-200-tint;
				padding: $spacing-s;
				margin-right: $spacing-xl;
			}
		}
	}
}

.slide-enter-active {
	transition-duration: 0.3s;
	transition-timing-function: ease-in;
}

.slide-leave-active {
	transition-duration: 0.3s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave-to {
	max-height: 700px;
	overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
	overflow: hidden;
	max-height: 0;
}
</style>
