<template>
	<div>
		<component
			:is="wrapper"
			class="card-container"
			:class="article.articleLink && 'card-link'"
			v-bind="article.articleLink"
			custom-type="div"
		>
			<div class="image-wrap">
				<ImageResponsive
					v-if="!textOnly && article.image"
					class="card-image"
					v-bind="article.image"
					@mousemove="(event) => zoomImage(event)"
					@mouseleave="(event) => unZoomImage(event.target)"
					:aspect-ratio="aspectRatio"
					:aspect-ratio-mobile="aspectRatioMobile"
					:aspect-ratio-tablet="aspectRatioTablet"
					auto-aspect
				/>
			</div>
			<div :class="['text', { center: article.center }]">
				<p
					v-if="article.category || article.subcategories?.length"
					class="categories"
				>
					<template v-if="article.subcategories?.length">
						<span
							v-for="(category, index) in article.subcategories"
							class="category"
							:key="`content-category-${index}`"
						>
							{{ category }}
						</span>
					</template>
					<template v-else>
						<span
							v-for="(category, index) in articleMainCategory"
							class="category"
							:key="`content-category-${index}`"
						>
							{{ category }}
						</span>
					</template>
				</p>
				<TnHeading
					type="h3"
					:size="titleSize"
					class="card-heading"
					v-editable-string
					responsive
					>{{ article.heading }}
				</TnHeading>
				<RichText
					v-if="article.ingress"
					:dark="dark"
					class="freetext card-ingress"
					:text="article.ingress"
				/>
			</div>
		</component>
	</div>
</template>

<script>
import LinkWrap from "../../common/Link/LinkWrap.vue";
import SupportsDarkMode from "../../../../mixins/platform/SupportsDarkMode.js";

export default defineNuxtComponent({
	name: "ContentGridCard",
	mixins: [SupportsDarkMode],

	props: {
		largeTitles: {
			type: Boolean,
			default: false,
		},
		textOnly: {
			type: Boolean,
			default: false,
		},
		article: {
			type: Object,
		},
		imageZoom: {
			type: Boolean,
			default: false,
		},
		show: {
			type: Boolean,
			default: false,
		},
		center: {
			type: Boolean,
			default: false,
		},
		aspectRatio: {
			type: String,
		},
		aspectRatioMobile: {
			type: String,
		},
		aspectRatioTablet: {
			type: String,
		},
	},

	computed: {
		wrapper() {
			if (this.article?.articleLink) {
				return LinkWrap;
			} else {
				return "div";
			}
		},
		articleMainCategory() {
			if (this.article.category.constructor === Array) {
				return this.article.category;
			} else {
				return [this.article.category];
			}
		},
		titleSize() {
			if (this.$store.getters.isMobile) return "2xs";

			return this.largeTitles ? "l" : "s";
		},
	},

	methods: {
		zoomImage(event) {
			if (this.imageZoom) {
				const rect = event.target.parentElement.getBoundingClientRect();
				const offsetX = Math.abs(event.clientX - rect.left) / 2;
				const offsetY = Math.abs(event.clientY - rect.top) / 2;
				event.target.style.transform = `scale(2) translate(-${offsetX}px, -${offsetY}px)`;
				event.target.style.webkitTransform = `scale(2) translate(-${offsetX}px, -${offsetY}px)`;
				event.target.style.opacity = "1";
			}
		},
		unZoomImage(target) {
			if (this.imageZoom) {
				target.querySelector("img").style.transform = "scale(1) translate(0px, 0px)";
				target.querySelector("img").style.webkitTransform = "scale(1) translate(0px, 0px)";
			}
		},
	},
});
</script>

<style lang="scss" scoped>
.category {
	display: inline-block;
	color: $color-neutrals-600-shade;

	@include font-text-bold-xs;

	@include breakpoint(mobile) {
		@include font-text-bold-2xs;
	}
}

.category:not(:first-child)::before {
	content: "|";
	padding: 0 $spacing-xs 0 $spacing-s;
}

@include breakpoint(mobile) {
	.category {
		display: none;

		&:nth-of-type(1),
		&:nth-of-type(2) {
			display: inline;
		}
	}
}

.categories + .card-heading {
	margin-top: $spacing-xs;
}

.image-wrap {
	display: block;
	position: relative;
	overflow: hidden;

	.card-image {
		height: 100%;
	}

	:deep(picture) {
		display: block;
	}

	:deep(img) {
		width: 100%;
		height: 100%;
		object-fit: cover;
		margin-left: 0;
		margin-bottom: 0;
		transform-origin: 0 0;
	}

	@include breakpoint(mobile) {
		height: auto;
	}
}

.card-heading {
	word-wrap: break-word;

	@include breakpoint(mobile) {
		@include font-title-2xs;
	}
}

.card-ingress {
	word-wrap: break-word;
	color: $color-neutrals-600-shade;
	margin-top: 4px; // Both desktop and mobile

	@include font-text-m;
}

.card.text-only .card-container {
	@include breakpoint(mobile) {
		grid-template-columns: 1fr;
	}
}

.card-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: $spacing-m;

	&.card-link:hover {
		h3 {
			text-decoration: underline;
		}

		:deep(img) {
			opacity: 0.6;
			transition: opacity 0.5s ease-out;
		}
	}

	@include breakpoint(mobile) {
		display: grid;
		grid-template-columns: 1fr 2fr;
		column-gap: 16px;
	}
}
</style>
